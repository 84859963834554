import React from "react";
import tw, { css } from "twin.macro";

import AnimatedSvg from "./AnimatedSvg";
import ListWithBullet from "./ListWithBullet";

interface Props {
  list: string[];
  header: string;
  revert?: boolean;
}

const baseFeature = css`
  ${tw`mb-3 pb-3 sm:(mb-6 pb-6) items-start text-center max-w-3xl mx-auto`}
  svg {
    ${tw`mb-4 fill-current text-turquoise w-40 mx-auto sm:(my-auto mx-12)`}
    min-width: 10rem;
  }
  li > svg {
    ${tw`inline h-6 ml--2 mr-1 mt--1 mb-auto transition w-auto`}
    min-width: 1rem;
  }
  li {
    ${tw`mx-4`}
  }
  div {
    ${tw`sm:(flex) justify-center m-auto`}
  }
`;
const featureRevert = css`
  ${baseFeature}
  ${tw`flex-row-reverse`}
`;

const ProfileTypeDescription: React.FC<Props> = ({ children, list, header, revert, ...rest }) => {
  return (
    <article css={baseFeature} {...rest}>
      <h2>{header}</h2>
      <div css={revert && featureRevert}>
        <AnimatedSvg>{children}</AnimatedSvg>
        <ListWithBullet list={list} />
      </div>
    </article>
  );
};

export default ProfileTypeDescription;
