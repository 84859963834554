import React from "react";
import { useTranslation } from "react-i18next";

import EquipmentOwnerSvg from "../../../static/images/equipment-owners.svg";
import ExpertSvg from "../../../static/images/experts.svg";
import MaterialOwnerSvg from "../../../static/images/material-owners.svg";
import { BookMeeting } from "../../components/BookMeeting";
import { Container } from "../../components/Container";
import ProfileTypeDescription from "../../components/ProfileTypeDescription";
import { getTranslationList } from "../../utils/common";

const ProfilesBlock: React.FC = ({ ...rest }) => {
  const { t } = useTranslation();

  const materialOwnerList = getTranslationList(t, "index.materialOwner.list");
  const equipmentOwnerList = getTranslationList(t, "index.equipmentOwner.list");
  const expertList = getTranslationList(t, "index.experts.list");

  return (
    <Container {...rest}>
      <section>
        <ProfileTypeDescription header={t("index.materialOwner.header")} list={materialOwnerList}>
          <MaterialOwnerSvg />
        </ProfileTypeDescription>

        <ProfileTypeDescription header={t("index.equipmentOwner.header")} list={equipmentOwnerList} revert={true}>
          <EquipmentOwnerSvg />
        </ProfileTypeDescription>

        <ProfileTypeDescription header={t("index.experts.header")} list={expertList}>
          <ExpertSvg />
        </ProfileTypeDescription>

        <BookMeeting />
      </section>
    </Container>
  );
};

export default ProfilesBlock;
